export const position = {
    37:{
        positionx: 1.2,
        positiony: 0.4,
        positionz: 5.8,
        glbScale:4
    },
    38:{
        positionx: -32,
        positiony: 0.4,
        positionz: 36, 
        glbScale:4
    },
    39:{
        positionx: 17.38,
        positiony: 0.4,
        positionz: 14.73,  
        glbScale:0.25
    },
    40:{
        positionx: 1,
        positiony: 0.4,
        positionz: 20,  
        glbScale:3
    },
    41:{
        positionx: -24,
        positiony: 0.4,
        positionz: 7.34,  
        glbScale:0.5

    },
    42:{
        positionx: 30,
        positiony: 0.4,
        positionz: -1,
        glbScale:2
  
    },
    43:{
        positionx: 1.2,
        positiony: 0.4,
        positionz: 5.8,  
        glbScale:4

    }
}