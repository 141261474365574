import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router.js'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import { position } from './js/position.js'
Vue.use(preview)
Vue.config.productionTip = false
Vue.prototype.appId = 'wxafa45f97f0d87241';
Vue.prototype.redirectUri = 'https://zh1.ky3d.com/';
Vue.prototype.exhibitionPostionXYZ = position



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
